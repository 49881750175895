import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Banner />
    <Container>
      <h1 style={{marginTop: "3rem", marginBottom: "1rem"}}>
        <i className="fas fa-fw fa-exclamation-triangle"></i>
        Not found
      </h1>
      <p>We couldn't find the page you were looking for.</p>
      <Link to="/">
        <i className="fas fa-fw fa-arrow-left"></i>
        Back to map
      </Link>
    </Container>
  </Layout>
)

export default NotFoundPage
